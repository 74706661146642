<!-- src/App.vue -->
<template>
  <div id="app">
    <router-view></router-view>  <!-- 路由出口 -->
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* 全局样式 */
</style>
