<template>
  <div class="toolbar fixed-bottom">
    <b-container fluid>
      <b-row no-gutters>
        <!-- 第1列 -->
        <b-col class="text-center" cols="6">
          <button class="toolbar-button" :class="['toolbar-button', activeItem === 'order' ? 'active' : '']"><a  href="/">订单</a></button>
        </b-col>
        <!-- 第2列 -->
        <b-col class="text-center" cols="6">
          <button class="toolbar-button" :class="['toolbar-button', activeItem === 'user' ? 'active' : '']"><a href="/user" >我的</a></button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ToolBar',
   props: {
    activeItem: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.toolbar-button.active a {
  color: #007bff; /* 高亮时的颜色 */
  font-weight: bold;
}
.toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
  z-index: 1000;
}

.toolbar-button {
  width: 100%;
  padding: 10px 0;
  border: none;
  background-color: transparent;
  font-size: 16px;
}
.toolbar-button a{
  text-decoration: none;
  color: #000000;
}
.toolbar-button:focus {
  outline: none;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
